exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-download-js": () => import("./../../../src/pages/download.js" /* webpackChunkName: "component---src-pages-download-js" */),
  "component---src-pages-galeria-js": () => import("./../../../src/pages/galeria.js" /* webpackChunkName: "component---src-pages-galeria-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-leasing-js": () => import("./../../../src/pages/leasing.js" /* webpackChunkName: "component---src-pages-leasing-js" */),
  "component---src-pages-o-marce-js": () => import("./../../../src/pages/o-marce.js" /* webpackChunkName: "component---src-pages-o-marce-js" */),
  "component---src-pages-oferta-js": () => import("./../../../src/pages/oferta.js" /* webpackChunkName: "component---src-pages-oferta-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-templates-category-page-js-content-file-path-opt-build-repo-content-category-accessories-mdx": () => import("./../../../src/templates/category-page.js?__contentFilePath=/opt/build/repo/content/category/accessories.mdx" /* webpackChunkName: "component---src-templates-category-page-js-content-file-path-opt-build-repo-content-category-accessories-mdx" */),
  "component---src-templates-category-page-js-content-file-path-opt-build-repo-content-category-backwashes-mdx": () => import("./../../../src/templates/category-page.js?__contentFilePath=/opt/build/repo/content/category/backwashes.mdx" /* webpackChunkName: "component---src-templates-category-page-js-content-file-path-opt-build-repo-content-category-backwashes-mdx" */),
  "component---src-templates-category-page-js-content-file-path-opt-build-repo-content-category-barber-mdx": () => import("./../../../src/templates/category-page.js?__contentFilePath=/opt/build/repo/content/category/barber.mdx" /* webpackChunkName: "component---src-templates-category-page-js-content-file-path-opt-build-repo-content-category-barber-mdx" */),
  "component---src-templates-category-page-js-content-file-path-opt-build-repo-content-category-furniture-mdx": () => import("./../../../src/templates/category-page.js?__contentFilePath=/opt/build/repo/content/category/furniture.mdx" /* webpackChunkName: "component---src-templates-category-page-js-content-file-path-opt-build-repo-content-category-furniture-mdx" */),
  "component---src-templates-category-page-js-content-file-path-opt-build-repo-content-category-processors-mdx": () => import("./../../../src/templates/category-page.js?__contentFilePath=/opt/build/repo/content/category/processors.mdx" /* webpackChunkName: "component---src-templates-category-page-js-content-file-path-opt-build-repo-content-category-processors-mdx" */),
  "component---src-templates-category-page-js-content-file-path-opt-build-repo-content-category-styling-mdx": () => import("./../../../src/templates/category-page.js?__contentFilePath=/opt/build/repo/content/category/styling.mdx" /* webpackChunkName: "component---src-templates-category-page-js-content-file-path-opt-build-repo-content-category-styling-mdx" */)
}

